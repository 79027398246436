<template>
  <span v-if="locale == 'ru'">
    <b-row
      align-h="center"
      align-v="center"
      class="justify-content-center flex-row text-center"
    >
      <b-col md="3" class="text-center">
        <img
          src="https://api.cryptocloud.plus/static/widget/img/logo.svg"
          alt
          class="logo-crypto my-auto mb-3"
        />
      </b-col>
      <b-col md="3" class="text-center">
        <img
          src="https://api.cryptocloud.plus/static/widget/img/trust.svg"
          alt=""
          class="logo-crypto trust my-auto"
        />
      </b-col>
    </b-row>
    <b-row
      align-h="center"
      align-v="center"
      class="justify-content-between flex-row text-center mt-4"
    >
      <b-col md="12" class="text-center">
        <div class="flex flex-row">
          <b-col md="4">
            <input
              v-model="amount"
              class="input-form-email"
              placeholder="Сумма"
              required
              min="1"
            />
            <span class="absolute-currency">{{ currency }}</span>
          </b-col>

          <b-col md="4">
            <input
              v-model="email"
              class="input-form-email"
              placeholder="Email"
            />
          </b-col>

          <b-col md="4" class="mx-auto">
            <a
              @click.prevent="alertData"
              class="btn-crypto mx-auto"
              v-if="!invoice_created"
            >
              <span class="centred">Подтвердить</span>
            </a>
            <a
              :href="pay_url"
              target="_blank"
              class="btn-crypto mx-auto"
              v-else
            >
              <span class="centred">Перейти к оплате</span>
            </a>
          </b-col>

          <span v-if="error != null" style="margin-top: 20px">{{ error }}</span>
        </div>
      </b-col>
    </b-row>
  </span>
  <span v-else-if="locale == 'en'">
    <b-row
      align-h="center"
      align-v="center"
      class="justify-content-center flex-row text-center"
    >
      <b-col md="3" class="text-center">
        <img
          src="https://api.cryptocloud.plus/static/widget/img/logo.svg"
          alt
          class="logo-crypto my-auto mb-3"
        />
      </b-col>
      <b-col md="3" class="text-center">
        <img
          src="https://api.cryptocloud.plus/static/widget/img/trust.svg"
          alt=""
          class="logo-crypto trust my-auto"
        />
      </b-col>
    </b-row>
    <b-row
      align-h="center"
      align-v="center"
      class="justify-content-between flex-row text-center mt-4"
    >
      <b-col md="12" class="text-center">
        <div class="flex flex-row">
          <b-col md="4">
            <input
              v-model="amount"
              class="input-form-email"
              placeholder="Amount"
              required
              min="1"
            />
            <span class="absolute-currency">{{ currency }}</span>
          </b-col>

          <b-col md="4">
            <input
              v-model="email"
              class="input-form-email"
              placeholder="Email"
            />
          </b-col>

          <b-col md="4" class="mx-auto">
            <a
              @click.prevent="alertData"
              class="btn-crypto mx-auto"
              v-if="!invoice_created"
            >
              <span class="centred">Confirm</span>
            </a>
            <a
              :href="pay_url"
              target="_blank"
              class="btn-crypto mx-auto"
              v-else
            >
              <span class="centred">Proceed to checkout</span>
            </a>
          </b-col>
        </div>
      </b-col>
    </b-row>
    <b-row
      align-h="center"
      align-v="center"
      class="justify-content-between flex-row text-center"
    >
      <span v-if="error != null" style="margin-top: 20px">{{ error }}</span>
    </b-row>
  </span>
</template>

<script>
import axios from "axios";
export default {
  name: "PayButton",
  props: {
    api_key: {
      type: String,
      default: "",
    },
    shop_id: {
      type: String,
      default: "",
    },
    currency: {
      type: String,
      default: "USD",
    },
    amount: {
      type: Number,
      default: 10,
    },
    locale: {
      type: String,
      default: "ru",
    },
  },
  data() {
    return {
      email: null,
      order_id: null,
      invoice_created: false,
      pay_url: null,
      error: null,
    };
  },
  methods: {
    alertData() {
      if (this.validateEmail()) {
        if ((this.amount != null) & (this.amount > 0)) {
          var currency = this.currency != undefined ? this.currency : "RUB";
          var currency = this.currency != undefined ? this.currency : "RUB";
          axios.defaults.headers.common["Authorization"] =
            "Token " + this.api_key;
          var url = "https://api.cryptocloud.plus/v1/invoice/create";
          var data = {
            shop_id: this.shop_id,
            amount: this.amount,
            order_id: this.email,
            email: this.email,
            currency: currency,
          };
          axios.post(url, data).then((response) => {
            if (response.data.status == "success") {
              try {
                window.open(response.data.pay_url, "_blank").focus();
              } catch {
                window.location.replace(response.data.pay_url);
                console.log("error open new tab");
              }
              this.pay_url = response.data.pay_url;
              this.invoice_created = false;
              this.amount = this.email = null;
            }
          });
        } else {
          this.error = "Некорректная сумма";
        }
      } else {
        this.error = "Некорректный Email";
      }
    },

    validateEmail() {
      var re =
        /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
      return re.test(String(this.email).toLowerCase());
    },
  },
  mounted() {},
};
</script>

<style scoped>
.btn-crypto {
  width: 88% !important;
  height: 48px !important;
  color: white !important;
  border: 2px solid #4a40c6 !important;
  background: #4a40c6 !important;
  border-radius: 10px !important;
  transition: 0.5s !important;
  font-size: 14px !important;
  /* padding: 12px 16px !important; */
  text-align: center !important;
  font-weight: 600 !important;
  display: block;
  text-decoration: none;
}
.col-md-3 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col-md-12 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
@media (min-width: 768px) {
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-md-4 {
    flex: 0 0 auto !important;
    width: 33.3% !important;
  }

  .flex-row {
    flex-direction: row !important;
    flex-wrap: nowrap;
  }
  .logo-crypto {
    width: 100% !important;
  }
}

.col-md-4 {
  position: relative;
  width: 100%;
}
.centred,
.btn-crypto .centred,
a.btn-crypto .centred {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  height: 100%;
  text-decoration: none !important;
}
.absolute-currency {
  position: absolute;
  top: 16.5px;
  right: 60px;
  font-weight: 700;
  color: #d0d0d0;
  font-size: 15px;
}
.align-items-center {
  align-items: center !important;
}
.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-center {
  justify-content: center !important;
}
.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}
.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}
.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--bs-gutter-y) * -1);
  margin-right: calc(var(--bs-gutter-x) * -0.5);
  margin-left: calc(var(--bs-gutter-x) * -0.5);
}
.input-form-email {
  border: 2px solid #e6e8ec !important;
  border-radius: 12px !important;
  padding: 0px 16px !important;
  height: 48px !important;
  left: 0px !important;
  top: 24px !important;
  width: 80% !important;
  margin-bottom: 20px !important;
  height: 48px !important;
}
.mt-4 {
  margin-top: 1.6rem;
}
.btn-crypto:hover,
.btn-crypto:focus,
.btn-crypto:hover > .centred,
.btn-crypto:focus > .centred {
  border-radius: 100px !important;
  transition: 0.5s border-radius;
  text-decoration: unset !important;
}
.flex {
  display: flex;
  /* flex-direction: column; */
  flex-wrap: wrap;
  justify-content: start;
}
.flex-row {
  flex-direction: column;
  flex-wrap: nowrap;
}

.flex-column {
  flex-direction: column;
}
.justify-content-between {
  align-items: center;
}
.text-center {
  text-align: center;
}
.logo-crypto {
  margin-bottom: auto !important;
  width: 100%;
}
@media (max-width: 768px) {
  .logo-crypto {
    margin-bottom: 20px !important;
    width: 80%;
  }
}
#app {
  border: 2px #f4f5f6 solid;
  padding: 20px;
  border-radius: 15px;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #2c3e50;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-decoration: none !important;
}
</style>
