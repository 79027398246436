<template>
  <div id="app">
  
        <Home :api_key="$root.api_key" :shop_id="$root.shop_id" :currency="$root.currency" :amount="$root.amount" :locale="locale"></Home>
   
  </div>
</template>
<script>
import Home from './views/PayButton.vue'
export default {
  props: ['api_key', 'shop_id', 'currency', 'amount', 'locale'],
  components: { Home }
}
</script>
<style scoped>
.btn-crypto {
  width: 88% !important;
  height: 48px !important;
  color: white !important;
  border: 2px solid #4a40c6 !important;
  background: #4a40c6 !important;
  border-radius: 10px !important;
  transition: 0.5s !important;
  font-size: 14px !important;
  /* padding: 12px 16px !important; */
  text-align: center !important;
  font-weight: 600 !important;
  display: block;
  text-decoration: none;
}
.col-md-3 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col-md-12 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
@media (min-width: 768px) {
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-md-4 {
    flex: 0 0 auto !important;
    width: 33.3% !important;
  }

  .flex-row {
    flex-direction: row !important;
    flex-wrap: nowrap;
  }
  .logo-crypto {
    width: 100% !important;
  }
}

.col-md-4 {
  position: relative;
  width: 100%;
}
.centred,
.btn-crypto .centred,
a.btn-crypto .centred {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  height: 100%;
  text-decoration: none !important;
}
.absolute-currency {
  position: absolute;
  top: 16.5px;
  right: 60px;
  font-weight: 700;
  color: #d0d0d0;
  font-size: 15px;
}
.align-items-center {
  align-items: center !important;
}
.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-center {
  justify-content: center !important;
}
.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}
.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}
.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--bs-gutter-y) * -1);
  margin-right: calc(var(--bs-gutter-x) * -0.5);
  margin-left: calc(var(--bs-gutter-x) * -0.5);
}
.input-form-email {
  border: 2px solid #e6e8ec !important;
  border-radius: 12px !important;
  padding: 0px 16px !important;
  height: 48px !important;
  left: 0px !important;
  top: 24px !important;
  width: 80% !important;
  margin-bottom: 20px !important;
  height: 48px !important;
}
.mt-4 {
  margin-top: 1.6rem;
}
.btn-crypto:hover,
.btn-crypto:focus,
.btn-crypto:hover > .centred,
.btn-crypto:focus > .centred {
  border-radius: 100px !important;
  transition: 0.5s border-radius;
  text-decoration: unset !important;
}
.flex {
  display: flex;
  /* flex-direction: column; */
  flex-wrap: wrap;
  justify-content: start;
}
.flex-row {
  flex-direction: column;
  flex-wrap: nowrap;
}

.flex-column {
  flex-direction: column;
}
.justify-content-between {
  align-items: center;
}
.text-center {
  text-align: center;
}
.logo-crypto {
  margin-bottom: auto !important;
  width: 100%;
}
@media (max-width: 768px) {
  .logo-crypto {
    margin-bottom: 20px !important;
    width: 80%;
  }
}
#app {
  border: 2px #f4f5f6 solid;
  padding: 20px;
  border-radius: 15px;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #2c3e50;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-decoration: none !important;
}

</style>
